<template>
  <b-container fluid>
    <div
      class="col-sm-12 mx-auto py-3 d-flex flex-column justify-content-center"
    >
      <div
        class="
          RateLogo
          d-flex
          my-3
          justify-content-center
          col-12 col-sm-8
          mx-auto
        "
      >
        <img src="@/assets/icon.png" class="img-fluid w-50" alt="logo" />
      </div>
      <div class="row" id="my-cards">
        <div
          v-for="item in itemsForList"
          v-bind:key="item.id"
          class="  text-right mb-2  col-12 col-sm-6 rate "
    
        >
          <b-card>
            <div class="row">
              <div class="col-sm-1 col-2 d-flex justify-content-start">
                <img
                  class=""
                  src="@/assets/userimage.png"
                  alt=""
                  width="50"
                  height="50"
                />
              </div>
              <div class="col-sm-7 col-7 mx-2">
                <h6 class="text-bold">{{ item.RateName }}</h6>
                <star-rating
                  class="mb-2"
                  :rating="parseFloat(item.RateVal1)"
                  :increment="0.01"
                  :read-only="true"
                  :star-size="16"
                  :show-rating="false"
                  v-bind:rtl="true"
                  active-color="#fe6a00"
                  :glow="1"
                  :animate="true"
                ></star-rating>
              </div>
            </div>
            <p class="text-center">{{ item.RateDesc }}</p>
          </b-card>
        </div>
    
      </div>
    <b-pagination
          class="my-3 mx-auto"
          v-model="currentPage"
          :total-rows="rateArr.length"
          :per-page="perPage"
          aria-controls="my-cards"
        ></b-pagination>
      <b-button
        variant="outline-secondary"
        class="col-sm-6 mx-auto mt-2"
        :to="{ name: 'Rate' }"
        >{{ $t("RateYamhad") }}
      </b-button>
    </div>
  </b-container>
</template>

<script>
import StarRating from "vue-star-rating";
import axios from "axios";
export default {
  components: {
    StarRating,
  },
  computed: {
    
     itemsForList() {
    return this.rateArr.slice(
      (this.currentPage - 1) * this.perPage,
      this.currentPage * this.perPage,
    );
  },
  },

  mounted() {
       this.$gtag.event('All Rates Page', { method: 'Google' })

    var self = this;
    var url = process.env.VUE_APP_DEVBASEURL + "/getRate";
    axios.get(url).then(function (resp) {
      //   console.log(resp.data);
      self.rateArr = resp.data;
      self.displayCar = true;
    });
  },
      metaInfo: {
    title: "Rates",
  },
  data() {
    return { perPage:4, currentPage: 1, rateArr: [], displayCar: false };
  },
};
</script>

<style scoped>
.rate {
 
  transition: all 0.4s ease-out;
}
.rate:hover {
  box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.1);
} 
 
</style>